.step-image {
  width: 316px;
  height: 112px;
  margin-right: auto;
  margin-left: auto;
  //margin-bottom: 20px;
}

.ThirdStep,
.SecondStep,
.FirstStep .Auth {
  button:not(.ant-btn-link):focus,
  button:not(.ant-btn-link):hover,
  button:not(.ant-btn-link):active {
    background: #3369E7;
    opacity: 0.9;
  }
  button:not(.ant-btn-link) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    max-width: 320px;
    height: 48px;
    background: #3369E7;
    border-radius: 6px;
    margin-top: 30px;
  }
  input {
     padding: 12px 10px;
     max-width: 320px;
     height: 48px;
     background: #FFFFFF;
     border: 1px solid #D2D2D7;
     border-radius: 4px;
   }
  .label {
    width: 320px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #172B4D;
    margin: 40px 0 12px;
  }
}

.ThirdStep,
.SecondStep,
.FirstStep .Auth,
.FirstStep .Verify {
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #172B4D;
    margin: 20px 0;
    text-align: center;
  }
  .subtitle {
    max-width: 520px;
    font-size: 16px;
    line-height: 24px;
    color: #172B4D;
    margin: 0 0 20px;
    text-align: center;
  }
}
.FirstStep {
  .Verify {
    .label {
      margin: 50px 0 20px;
      font-size: 14px;
      line-height: 20px;
      color: #172B4D;
    }
    .retry-link {
      height: 20px;
      line-height: 20px;
      color: #3369E7;
      margin: 70px 0 0;
    }
    .cancel-link {
      color: #3369E7;
      margin: 8px 0;
    }
  }
  .Auth {
    .footer-text {
      max-width: 320px;
      line-height: 20px;
      color: #5E6C84;
    }
  }
}

.ThirdStep,
.SecondStep {
  .divider {
    width: 320px;
    height: 0;
    opacity: 0.15;
    border: 1px solid #000000;
    margin: 20px 0 0;
  }

  input {
    margin-top: 20px;
  }
}

.SecondStep {
  .label {
    max-width: 320px;
    font-weight: normal;
    margin: 12px 0 0;
  }
}

.ThirdStep {
  .label {
    font-weight: 500;
    line-height: 20px;
    margin: 20px 0;

    &.radio {
      font-weight: normal;
      margin: 0;
    }
  }
}

.step-1-image {
  width: 356px;
  height: auto;
}

.step-2-image {
  width: 300px;
  height: auto;
}

.step-3-image {
  height: auto;
  width: 570px;
  margin: -40px 0;
}

.step-3-image-offset {
  margin-left: 15px;
}

.mt-big {
  margin-top: 30px;
}
.mt15 {
  margin-top: 15px;
}
.step-logo {
  width: 31px;
  height: auto;
}
.decimal-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  width: 48px;
  height: 60px;
  font-size: 29px;
  background: #FFFFFF;
  border: 1px solid #D2D2D7;
  box-sizing: border-box;
  border-radius: 4px;
}
.decimal-input:not(:last-of-type) {
  margin-right: 16px;
}
