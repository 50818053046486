.App .wrapper {
  min-height: calc(100vh - 78px);
  padding: 10px 50px;
}
.logo {
  padding: 20px 50px;
}

.logo-image {
  height: 30px;
  width: auto;
}
.text-center {
  text-align: center;
}
.text-subtitle {
  font-weight: bold;
}

.mb15 {
  margin-bottom: 15px;
}
.mb30 {
  margin-bottom: 30px;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.ant-layout {
  background-color: #fff;
  background: #fff;
}

.business-select {
  width: 25%;
}